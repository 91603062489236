<template>
  <v-container>
    <v-row class="justify-center text-center">
      <v-col cols="12">
        <h2 class="font-weight-bold mt-4 mb-10">
          Lista de formularios no revisados
        </h2>

        <v-row class="justify-center mb-5">
          <v-btn color="primary" @click="refrescarFormularios"
            >Refrescar <v-icon>mdi-refresh</v-icon></v-btn
          >
        </v-row>

        <!-- Tabla que muestra los formularios que no han sido revisados -->
        <v-data-table
          v-if="!cargando_resultados"
          :items-per-page="-1"
          :header-props="{ sortByText: 'Ordenar por' }"
          :headers="headers"
          :items="formularios"
          class="text-center"
          elevation="0"
        >
          <!-- Acción que permite seleccionar el formulario y revisarlo -->
          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn small color="primary" @click="checkForm(item.id_formulario)">
              <v-icon small class="mr-1">mdi-text-box-search</v-icon>
              Revisar
            </v-btn>
          </template>

          <template v-slot:item.tarifa="{ item }">
            <div v-if="item.tipo_revision != 0 && item.tipo_revision != 2">
              ${{ item.tarifa | moneda }}
            </div>
            <div v-else>No Aplica</div>
          </template>

          
        </v-data-table>
        <v-col
          cols="12"
          v-else
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            height: 200px;
          "
        >
          <v-spacer></v-spacer>
          <v-progress-circular
            :size="100"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <v-spacer></v-spacer>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiLista from "../lista_preguntas/services/ListaFormsDataService.js";

export default {
  data() {
    return {
      cargando_resultados: false,
      formularios: [], //Lista de formularios no revisados

      //Encabezados de la tabla
      headers: [
        {
          text: "N° Orden Trabajo",
          value: "ot_id",
          align: "start",
          sortable: false,
          filterable: false,
        },
      //   {
      //   text: "Tarifa",
      //   value: "tarifa",
      //   align: "start",
      // },
        {
          text: "Nombre del técnico",
          align: "start",
          sortable: false,
          filterable: false,
          value: "tecnico",
        },

        {
          text: "Placa",
          sortable: false,
          filterable: false,
          value: "placa_vehiculo",
        },

        {
          text: "Fecha de creación",
          sortable: false,
          filterable: false,
          value: "fecha_creacion",
        },

        {
          text: "Comentarios del operario",
          sortable: false,
          filterable: false,
          value: "comentarios_operario",
        },

        {
          text: "Acciones",
          sortable: false,
          filterable: false,
          value: "acciones",
        },
      ],
    };
  },

  async created() {
    this.$store.state.stateLoad = true;
    //Trae los formularios que todavía no han sido revisados
    await apiLista.showNotRevisedForms().then((response) => {
      this.formularios = response.data;
    });
    this.$store.state.stateLoad = false;
  },

  methods: {
    //Permite revisar el formulario usando el id del formulario seleccionado
    checkForm(id) {
      this.$router.push({
        name: "formularioRevision",
        params: { id: id, revision: 0 },
      });
    },

    async refrescarFormularios() {
      this.cargando_resultados = true;
      //Trae los formularios que todavía no han sido revisados
      await apiLista.showNotRevisedForms().then((response) => {
        this.formularios = response.data;
      });
      this.cargando_resultados = false;
    },
  },
};
</script>

<style scoped>
>>> .v-data-table__wrapper {
  background-color: rgba(236, 236, 236, 0.8) !important;
  border-radius: 10px !important;
}

>>> td {
  border-bottom: 1px solid #adadad !important;
}

>>> .v-data-table__mobile-table-row {
  margin: 20px !important;
  border: 1px solid #adadad !important;
  border-radius: 10px !important;
}

>>> .v-data-table__mobile-row:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
</style>
